import {twMerge} from 'tailwind-merge';
import traitRatings from 'src/utils/traitRatings';
import {ratingColors} from 'src/constants';
import TraitIcon from '../TraitIcon';

type Props = {
  review: Review;
  className?: string;
};

const TraitRatingsRow = ({review, className}: Props) => {
  return (
    <div className={twMerge('flex gap-3', className)}>
      {traitRatings.map((trait) => {
        const rating = review[trait.reviewKey];

        if (!rating) return null;

        return (
          <div key={trait.type} className="flex items-center gap-1">
            <span className="text-base">{rating}</span>
            <TraitIcon type={trait.type} size={20} color={ratingColors[rating - 1]} />
          </div>
        );
      })}
    </div>
  );
};

export default TraitRatingsRow;
