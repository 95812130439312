import {Fragment} from 'react';
import dynamic from 'next/dynamic';
import getParagraphs from 'src/utils/getParagraphs';
import TraitRatingsRow from './TraitRatingsRow';
import SmallRating from '../SmallRating';
import {moreFill} from 'src/utils/icons';
import BaseButton from '../BaseButton';
import UserTag from '../UserTag';

const MyReviewDropdown = dynamic(() => import('../MyReviewDropdown'));

type Props = {
  review: Review;
  place: Partial<Place> | PlacePageType;
  session: IronSession | null;
};

const ReviewCard = ({review, place, session}: Props) => {
  const isMyReview = session && review.user?._id === session?._id;
  const paragraphs = getParagraphs(review.text);

  return (
    <article className="flex flex-col btn-border bg-[--button-bg] rounded-2xl py-5 px-6 items-start">
      <div className="flex mb-5 justify-between w-full">
        <UserTag user={review.user} />

        {isMyReview ? (
          <MyReviewDropdown place={place} initialReview={review} session={session}>
            <BaseButton icon={moreFill} buttonVariant="text" className="w-[42px]" />
          </MyReviewDropdown>
        ) : null}
      </div>

      <p className="text-body mr-2 whitespace-pre-line">
        {paragraphs.map((paragraph, index) => (
          <Fragment key={index}>
            {paragraph}

            {index !== paragraphs.length - 1 ? <br /> : null}
          </Fragment>
        ))}
      </p>

      <div className="flex mt-5 gap-3">
        <SmallRating rating={review.mainRating} starSize={14} className="text-base" />

        <TraitRatingsRow review={review} />
      </div>
    </article>
  );
};

export default ReviewCard;
