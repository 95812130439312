'use client';

import {H} from '@highlight-run/next/client';
import {useEffect, startTransition} from 'react';

type Props = {
  session: IronSession | null;
};

const HighlightLoader = ({session}: Props) => {
  useEffect(() => {
    const isLocalhost = typeof window === 'object' && window.location.host?.startsWith('localhost');
    const shouldRecord = !isLocalhost && (!session || !session?.isAdmin);

    if (shouldRecord)
      startTransition(() => {
        H.start();

        if (session) H.identify(session._id || '', {name: session.name || '', nameId: session.nameId || ''});
      });
  }, [session?.email]);

  return null;
};

export default HighlightLoader;
