import {forwardRef} from 'react';
import {Slot} from '@radix-ui/react-slot';

type Props = {
  ratio?: number;
  children?: React.ReactNode;
};

const AspectRatio = forwardRef<HTMLDivElement, Props>(({ratio = 1, children}, forwardedRef) => {
  return (
    <div
      style={{
        // ensures inner element is contained
        position: 'relative',
        // ensures padding bottom trick maths works
        width: '100%',
        paddingBottom: `${100 / ratio}%`
      }}
    >
      <Slot
        ref={forwardedRef}
        style={{
          // ensures children expand in ratio
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }}
      >
        {children}
      </Slot>
    </div>
  );
});

export default AspectRatio;
