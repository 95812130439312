'use client';

import dynamic from 'next/dynamic';
import useGalleryImage from 'src/hooks/useGalleryImage';
import fetcher from 'src/utils/fetcher';

const ImageGalleryModal = dynamic(() => import('./ImageGalleryModal'), {ssr: false});

type Props = {
  place: Partial<Place> | PlacePageType;
};

const PlaceImageGallery = ({place}: Props) => {
  const {galleryImageId, closeGallery, replaceGalleryImage} = useGalleryImage();

  const initialImages = place.images || [];

  if (!galleryImageId) return null;

  const fetchImages = () =>
    fetcher<Place>({
      url: '/api/places/place',
      query: {countryCode: place.countryCode, placeNameId: place.nameId, withAllImages: 'true'}
    }).then((data) => data?.images || []);

  return (
    <ImageGalleryModal
      placeId={place._id}
      placeName={place.name || ''}
      countryName={place.countryName || ''}
      fetchImages={fetchImages}
      setActiveImageId={replaceGalleryImage}
      onClose={closeGallery}
      initialImages={initialImages}
      activeImageId={galleryImageId}
    />
  );
};

export default PlaceImageGallery;
