'use client';

import type {FormEvent} from 'react';
import {m as motion} from 'framer-motion';
import {useRouter} from 'next/navigation';
import * as Form from '@radix-ui/react-form';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import TextInput, {getValuesFromFormEvent} from './form/TextInput';
import fetcher from 'src/utils/fetcher';
import BaseButton from './BaseButton';

type EditableValues = {
  name: string;
  description: string;
};

type Props = {
  onClose?: () => void;
};

const CreateListSection = ({onClose}: Props) => {
  const queryClient = useQueryClient();
  const router = useRouter();

  const {mutateAsync: createList, isPending} = useMutation({
    mutationFn: (values: EditableValues) =>
      fetcher({
        url: '/api/lists',
        options: {method: 'POST'},
        body: values
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['myLists']});

      router.refresh();

      if (onClose) onClose();
    }
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    const values = getValuesFromFormEvent(event, ['name', 'description']) as EditableValues;

    event.preventDefault();
    createList(values);
  };

  return (
    <motion.div layout>
      <Form.Root className="flex flex-col mt-6" onSubmit={onSubmit}>
        <TextInput
          name="name"
          label="Name"
          required
          autoFocus
          validations={[{match: 'valueMissing', children: 'What should be the name of your list?'}]}
        />

        <TextInput name="description" label="Description" textarea />

        <div className="flex mt-2 gap-4">
          <BaseButton type="button" onPress={onClose} label="Cancel" buttonVariant="secondary" className="flex-grow" />

          <Form.Submit asChild disabled={isPending}>
            <BaseButton label="Create list" isLoading={isPending} buttonVariant="primary" className="flex-grow" />
          </Form.Submit>
        </div>
      </Form.Root>
    </motion.div>
  );
};

export default CreateListSection;
