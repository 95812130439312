'use client';

import {twMerge} from 'tailwind-merge';
import type {PlacePageSite} from 'server/apiHandlers/getSites';
import SiteCard from './SiteCard';

type Props = {
  places: PlacePageSite[];
  className?: string;
};

const SitesSection = ({places, className}: Props) => {
  const sitesWithContent = places.filter((place) => place.name);

  if (!sitesWithContent.length) return null;

  return (
    <>
      <div
        className={twMerge(
          '[content-visibility:auto] w-full flex max-[800px]:flex gap-8 max-[800px]:gap-4 overflow-x-auto no-scrollbar',
          className
        )}
      >
        {sitesWithContent.map((place) => {
          return <SiteCard key={place._id} place={place} href={`/${place.countryCode}/${place.nameId}`} />;
        })}
      </div>
    </>
  );
};

export default SitesSection;
