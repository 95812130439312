const getImagesRowSizes = (images: Array<Partial<PlaceImage> | undefined>, baseHeight: number) => {
  const adjustedSizes = images.map((image) => {
    const imageRatio = (image?.width || 1) / (image?.height || 1);
    const imageWidth = baseHeight * imageRatio;

    return {
      width: imageWidth,
      height: baseHeight
    };
  });

  const overallAdjustedWidth = adjustedSizes.reduce((acc, size) => acc + size.width, 0);
  const tallestAdjustedHeight = adjustedSizes.reduce((acc, size) => Math.max(acc, size.height), 0);

  const sizesWithWidthPercentage = adjustedSizes.map((size) => ({
    ...size,
    widthPercentage: (size.width / Math.max(overallAdjustedWidth, tallestAdjustedHeight)) * 100
  }));

  return sizesWithWidthPercentage;
};

export default getImagesRowSizes;
