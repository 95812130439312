'use client';

import {twMerge} from 'tailwind-merge';
import Dropdown, {type DropdownMenuItem} from './Dropdown';
import {moreFill} from 'src/utils/icons';
import BaseButton from './BaseButton';

type Props = {
  place: Partial<PlacePageType>;
  extraMenuItems?: DropdownMenuItem[];
  buttonVariant?: 'primary' | 'secondary' | 'text' | 'translucent';
  className?: string;
};

const PlaceMoreButton = ({place, extraMenuItems = [], buttonVariant, className}: Props) => {
  const menuItems = [...extraMenuItems];

  return (
    <Dropdown items={menuItems}>
      <BaseButton
        icon={moreFill}
        buttonVariant={buttonVariant || 'secondary'}
        size="large"
        iconSize={22}
        className={twMerge('w-[48px]', className)}
      />
    </Dropdown>
  );
};

export default PlaceMoreButton;
