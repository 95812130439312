import {useEffect} from 'react';
import {atom, useAtom, useSetAtom} from 'jotai';

const galleryImageIdState = atom<string | null>(null);
const galleryPlaceIdState = atom<string | null>(null);

const useGalleryImage = () => {
  const [galleryImageId, setGalleryImageIdAtom] = useAtom(galleryImageIdState);
  const [galleryPlaceId, setGalleryPlaceIdAtom] = useAtom(galleryPlaceIdState);

  useEffect(() => {
    const url = new URL(String(window.location));

    const imageId = url.searchParams.get('image');
    const placeId = url.searchParams.get('place');

    // @ts-ignore
    if (imageId) setGalleryImageIdAtom(imageId);
    // @ts-ignore
    if (placeId) setGalleryPlaceIdAtom(placeId);
  }, []);

  const replaceGalleryImage = (imageId: string, placeId?: string) => {
    // @ts-ignore
    setGalleryImageIdAtom(imageId);
    // @ts-ignore
    if (placeId) setGalleryPlaceIdAtom(placeId);

    const url = new URL(String(window.location));

    url.searchParams.set('image', imageId);
    if (placeId) url.searchParams.set('place', placeId);

    window.history.replaceState(null, '', url.toString());
  };

  const closeGallery = () => {
    // @ts-ignore
    setGalleryImageIdAtom(null);

    const url = new URL(String(window.location));

    url.searchParams.delete('image');
    url.searchParams.delete('place');

    window.history.replaceState(null, '', url.toString());
  };

  return {galleryImageId, galleryPlaceId, replaceGalleryImage, closeGallery};
};

export const useSetGalleryImageId = (replace?: boolean) => {
  // @ts-ignore
  const setGalleryImageIdAtom = useSetAtom(galleryImageIdState);
  // @ts-ignore
  const setGalleryPlaceIdAtom = useSetAtom(galleryPlaceIdState);

  const setGalleryImageId = (imageId: string, placeId?: string) => {
    setGalleryImageIdAtom(imageId);
    if (placeId) setGalleryPlaceIdAtom(placeId);

    const url = new URL(String(window.location));

    url.searchParams.set('image', imageId);

    if (placeId) url.searchParams.set('place', placeId);
    if (replace) return window.history.replaceState(null, '', url.toString());

    return window.history.pushState(null, '', url.toString());
  };

  return setGalleryImageId;
};

export default useGalleryImage;
