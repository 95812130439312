'use client';

import RatePlaceButtonWrapper from '../rate-place/RatePlaceButtonWrapper';
import useWidthBreakpoints from 'src/utils/useWidthBreakpoints';
import useMounted from 'src/hooks/useMounted';
import {starFill} from 'src/utils/icons';
import BaseButton from '../BaseButton';
import ReviewCard from './ReviewCard';

type ReviewColumns = {one: Review[]; two: [Review[], Review[]]; three: [Review[], Review[], Review[]]};

const reviewPaddingSpace = 280;

const reviewsToColumns = (reviews: Review[]): ReviewColumns => {
  const columns: ReviewColumns = {one: [], two: [[], []], three: [[], [], []]};

  if (!reviews) return columns;

  let twoColumns1Length = 0;
  let twoColumns2Length = 0;

  const twoColumns1: Review[] = [];
  const twoColumns2: Review[] = [];

  let threeColumns1Length = 0;
  let threeColumns2Length = 0;
  let threeColumns3Length = 0;

  const threeColumns1: Review[] = [];
  const threeColumns2: Review[] = [];
  const threeColumns3: Review[] = [];

  for (let index = 0; index < reviews.length; index++) {
    const review = reviews[index];

    const reviewLength = (review.text?.length || 0) + reviewPaddingSpace;

    // Two columns logic
    if (twoColumns1Length > twoColumns2Length) {
      twoColumns2.push(review);
      twoColumns2Length += reviewLength;
    } else {
      twoColumns1.push(review);
      twoColumns1Length += reviewLength;
    }

    // Three columns logic
    if (threeColumns1Length <= threeColumns2Length && threeColumns1Length <= threeColumns3Length) {
      threeColumns1.push(review);
      threeColumns1Length += reviewLength;
    } else if (threeColumns2Length <= threeColumns1Length && threeColumns2Length <= threeColumns3Length) {
      threeColumns2.push(review);
      threeColumns2Length += reviewLength;
    } else {
      threeColumns3.push(review);
      threeColumns3Length += reviewLength;
    }
  }

  columns.one = reviews;
  columns.two = [twoColumns1, twoColumns2];
  columns.three = [threeColumns1, threeColumns2, threeColumns3];

  return columns;
};

type Props = {
  reviews: Review[];
  place: Partial<PlacePageType>;
  onReviewUpdated?: () => void;
  session: IronSession | null;
};

const ReviewsGrid = ({reviews, place, onReviewUpdated, session}: Props) => {
  const isMounted = useMounted();
  const breakpoint = useWidthBreakpoints([900, 1400], 0);

  const {one, two, three} = reviewsToColumns(reviews);

  return (
    <div>
      {/* One column */}
      {isMounted && reviews.length && breakpoint !== 0 ? null : (
        <div className="hidden max-[900px]:flex flex-col gap-8 max-[500px]:gap-5">
          {one.map((review) => (
            <ReviewCard key={review._id} review={review} place={place} session={session} />
          ))}
        </div>
      )}

      {/* Two columns */}
      {isMounted && reviews.length && breakpoint !== 1 ? null : (
        <div className="hidden max-[900px]:hidden max-[1400px]:flex gap-8">
          {two.map((column, index) => {
            return (
              <div key={index} className="flex flex-col w-0 grow gap-8">
                {column.map((review) => (
                  <ReviewCard key={review._id} review={review} place={place} session={session} />
                ))}
              </div>
            );
          })}
        </div>
      )}

      {/* Three columns */}
      {isMounted && reviews.length && breakpoint !== 2 ? null : (
        <div className="max-[1400px]:hidden flex gap-8">
          {three.map((column, index) => {
            return (
              <div key={index} className="flex flex-col w-0 grow gap-8">
                {column.map((review) => (
                  <ReviewCard key={review._id} review={review} place={place} session={session} />
                ))}
              </div>
            );
          })}
        </div>
      )}

      {reviews.length ? null : (
        <div className="flex justify-center items-center px-10 max-[800px]:px-4 py-16 max-[1000px]:py-12 max-[800px]:py-10 max-[500px]:pb-6 relative">
          <div className="absolute w-full h-full bottom-0 border border-solid border-[rgb(23_10_37_/_0.07)] dark:border-[rgb(255_255_255_/_0.1)] rounded-3xl" />

          <div className="relative flex flex-col items-center">
            <h3 className="text-3xl text-center mb-2 max-[800px]:text-2xl [text-wrap:balance]">
              Be the first to review {place.name}
            </h3>
            <p className="text-center mb-8 [text-wrap:balance]">
              Tell us about it! Is it place worth visiting, are you coming back?
            </p>

            <RatePlaceButtonWrapper place={place} onReviewUpdated={onReviewUpdated}>
              <BaseButton
                className="max-[500px]:grow max-[500px]:w-full"
                buttonVariant="primary"
                icon={starFill}
                iconSize={18}
                size="large"
              >
                <span className="truncate grow max-[500px]:w-0">{`Review ${place.name}`}</span>
              </BaseButton>
            </RatePlaceButtonWrapper>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewsGrid;
