'use client';

import MobileHeroImages from './MobileHeroImages';
import fetcher from 'src/utils/fetcher';

type Props = {
  place: Partial<PlacePageType>;
};

const PlaceMobileHeroImages = ({place}: Props) => {
  return (
    <MobileHeroImages
      initialImages={place.images || []}
      queryKey={['placeWithAllImages', place._id || '']}
      placeName={place.name || ''}
      countryName={place.countryName || ''}
      getImages={() =>
        fetcher<Place>({
          url: '/api/places/place',
          query: {countryCode: place.countryCode, placeNameId: place.nameId, withAllImages: 'true'}
        }).then((data) => data?.images || [])
      }
    />
  );
};

export default PlaceMobileHeroImages;
