import {memoize} from 'lodash';
import chunk from 'lodash/chunk';
import {decodeBlurHash} from 'fast-blurhash';
import tinycolor, {type ColorFormats} from 'tinycolor2';
import quantize from 'src/utils/quantize';

const getBackgroundColor = (colorHsl: ColorFormats.HSLA): number[] => {
  const color = tinycolor(colorHsl);

  const luminanceDeficit = 0.9 - colorHsl.l;

  const newColor = luminanceDeficit > 0 ? color.lighten(luminanceDeficit * 100) : color;
  const newColorRgb = newColor.toRgb();

  return [newColorRgb.r, newColorRgb.g, newColorRgb.b];
};

const getDarkBackgroundColor = (colorHsl: ColorFormats.HSLA): number[] => {
  const color = tinycolor(colorHsl);

  const luminanceExcess = colorHsl.l - 0.15;

  const newColor = luminanceExcess > 0 ? color.darken(luminanceExcess * 100) : color;

  const newColorRgb = newColor.toRgb();

  return [newColorRgb.r, newColorRgb.g, newColorRgb.b];
};

const getTextColor = (colorHsl: ColorFormats.HSLA): number[] => {
  const color = tinycolor(colorHsl);

  const luminanceExcess = colorHsl.l - 0.2;

  const newColor = luminanceExcess > 0 ? color.darken(luminanceExcess * 100) : color;
  const newColorRgb = newColor.toRgb();

  return [newColorRgb.r, newColorRgb.g, newColorRgb.b];
};

const getDarkTextColor = (colorHsl: ColorFormats.HSLA): number[] => {
  const color = tinycolor({h: colorHsl.h, s: colorHsl.s + 0.5, l: colorHsl.l});

  const luminanceDeficit = 0.9 - colorHsl.l;

  const newColor = luminanceDeficit > 0 ? color.lighten(luminanceDeficit * 100) : color;

  const newColorRgb = newColor.toRgb();

  return [newColorRgb.r, newColorRgb.g, newColorRgb.b];
};

type GeneratedColors = {
  backgroundColor: number[];
  darkBackgroundColor: number[];
  textColor: number[];
  darkTextColor: number[];
  isDark: boolean;
};

const getCssVariables = ({
  backgroundColor,
  darkBackgroundColor,
  textColor,
  darkTextColor,
  isDark
}: GeneratedColors): Record<string, string> => {
  const bgColorString = backgroundColor.join(',');
  const darkBgColorString = darkBackgroundColor.join(',');

  const cardBgColorString = isDark ? darkBgColorString : bgColorString;

  return {
    '--attraction-bg-color': `rgb(${bgColorString})`,
    '--dark-attraction-bg-color': `rgb(${darkBgColorString})`,

    '--attraction-text-color': `rgb(${textColor.join(',')})`,
    '--dark-attraction-text-color': `rgb(${darkTextColor.join(',')})`,

    '--card-bg-color': isDark ? `rgb(${darkBgColorString})` : `rgb(${bgColorString})`,
    '--dark-card-bg-color': `rgb(${darkBgColorString})`,

    '--card-text-color': isDark ? `rgb(${darkTextColor.join(',')})` : `rgb(${textColor.join(',')})`,
    '--dark-card-text-color': `rgb(${darkTextColor.join(',')})`,

    '--attraction-gradient': `linear-gradient(
      to bottom,
      rgba(${cardBgColorString},1) 0%,
      rgba(${cardBgColorString},0.98) 4%,
      rgba(${cardBgColorString},0.95) 10%,
      rgba(${cardBgColorString},0.8) 25%,
      rgba(${cardBgColorString},0) 100%
    )`,
    '--dark-attraction-gradient': `linear-gradient(
      to bottom,
      rgba(${darkBgColorString},1) 0%,
      rgba(${darkBgColorString},0.98) 4%,
      rgba(${darkBgColorString},0.95) 10%,
      rgba(${darkBgColorString},0.8) 25%,
      rgba(${darkBgColorString},0) 100%
    )`,
    '--attraction-shadow': `
      1.6px 4.5px 3.6px rgba(${darkBgColorString}, 0.035),
      4.4px 12.5px 10px rgba(${darkBgColorString}, 0.05),
      10.6px 30.1px 24.1px rgba(${darkBgColorString}, 0.065),
      35px 100px 80px rgba(${darkBgColorString}, 0.1)
    `
  };
};

type AttractionColors = {
  cssVariables: Record<string, string>;
  isDark: boolean;
};

const getAttractionColorsFromBlur = memoize((blurHash: string | undefined): AttractionColors => {
  if (!blurHash)
    return {
      cssVariables: getCssVariables({
        backgroundColor: [245, 245, 245],
        darkBackgroundColor: [33, 28, 35],
        textColor: [37, 37, 37],
        darkTextColor: [255, 255, 255],
        isDark: false
      }),
      isDark: false
    };

  const pixels = decodeBlurHash(blurHash, 12, 12);
  const colors = chunk(pixels, 4);

  const topColors = colors.slice(0, 6 * 12);

  const colorMap = quantize(topColors, 2);
  const colorPalette = colorMap.palette();
  const dominantColor = colorPalette[0];

  const color = tinycolor({r: dominantColor[0], g: dominantColor[1], b: dominantColor[2]});
  const colorHsl = color.toHsl();

  const backgroundColor = getBackgroundColor(colorHsl);
  const darkBackgroundColor = getDarkBackgroundColor(colorHsl);

  const textColor = getTextColor(colorHsl);
  const darkTextColor = getDarkTextColor(colorHsl);

  const isDark = colorHsl.l < 0.5;

  return {
    cssVariables: getCssVariables({backgroundColor, darkBackgroundColor, textColor, darkTextColor, isDark}),
    isDark
  };
});

export default getAttractionColorsFromBlur;
