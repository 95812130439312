import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Arrows/arrow-down-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Arrows/arrow-down-s-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Arrows/arrow-left-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Arrows/arrow-left-s-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Arrows/arrow-right-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Arrows/arrow-right-s-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Arrows/arrow-right-up-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Arrows/arrow-up-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Arrows/skip-down-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Arrows/skip-up-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Buildings/building-2-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Buildings/building-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Business/flag-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Business/flag-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Design/drop-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Design/drop-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Design/magic-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Design/pencil-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Development/braces-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Device/smartphone-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Document/article-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Document/file-copy-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Document/file-edit-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Editor/align-left.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Editor/info-i.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Finance/trophy-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Logos/bard-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Logos/instagram-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Logos/twitter-x-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Map/anchor-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Map/compass-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Map/earth-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Map/map-2-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Map/map-pin-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Map/riding-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Map/road-map-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Map/signpost-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Map/subway-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Media/image-add-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Media/image-edit-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Others/game-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Others/seedling-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Others/tree-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/add-circle-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/add-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/check-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/checkbox-blank-circle-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/checkbox-circle-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/checkbox-circle-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/close-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/delete-bin-3-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/external-link-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/function-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/history-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/login-circle-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/logout-circle-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/menu-4-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/more-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/search-2-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/settings-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/share-2-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/star-fill.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/thumb-down-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/thumb-up-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/System/time-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/User & Faces/star-smile-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/User & Faces/user-smile-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Weather/moon-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Weather/sparkling-2-line.svg");
;
import(/* webpackMode: "eager" */ "/app/node_modules/remixicon/icons/Weather/sun-line.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/bucketIcon.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/foodIcon.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/otherPlaceIcon.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/peakIcon.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/peopleIcon.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/ratingStar.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/safetyIcon.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/spacesIcon.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/treeIcon.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/valueIcon.svg");
;
import(/* webpackMode: "eager" */ "/app/public/icons/villageIcon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/components/attractions/SitesSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/auth/AuthModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/ButtonComponentWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/ImageDeck.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/SkipRenderOnClient.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/SmallLoader.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/common/Markdown.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/FadingImage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/FastLink.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/gallery/PlaceImageGallery.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/HeroImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/loaders/HighlightLoader.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/place-page/MapImage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/place-page/PlaceMobileHeroImages.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/place-page/ReviewsGrid.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/PlaceAdminMoreButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default","PlaceCardsGrid","PlaceCardSkeleton"] */ "/app/src/components/PlaceCard.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/rate-place/RatePlaceButtonWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/rate-place/RatePlaceModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/save-place/SavePlaceButtonWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/save-place/SavePlaceModal.tsx");
