'use client';

import Link from 'next/link';
import {twJoin} from 'tailwind-merge';
import getAttractionColorsFromBlur from 'src/utils/getAttractionColorsFromBlur';
import getImageUrl from 'src/utils/getImageUrl';
import {arrowRightLine} from 'src/utils/icons';
import BaseButton from '../BaseButton';
import FastImage from '../FastImage';
import RemixIcon from '../RemixIcon';

type Props = {
  place: Partial<Place>;
  href: string;
};

const SiteCard = ({place, href}: Props) => {
  const image = place.images?.[0];

  const {isDark, cssVariables} = getAttractionColorsFromBlur(image?.blurHash);

  return (
    <article
      className={twJoin(
        'relative @container group flex basis-[min(27vw,400px)] min-w-[260px] shrink-0 dark:![--card-bg-color:--dark-card-bg-color] dark:![--card-text-color:--dark-card-text-color] dark:![--attraction-gradient:--dark-attraction-gradient] dark:! text-[--card-text-color]',
        isDark && 'dark'
      )}
      style={cssVariables}
    >
      {image ? (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-[--card-bg-color] rounded-2xl @[220px]:rounded-3xl overflow-hidden">
          <div className="absolute w-full bottom-0 aspect-[1.5/1]">
            <FastImage
              src={getImageUrl(image.path, 'thumb')}
              fill
              alt={`Photo of ${place.name} in ${place.countryName}`}
              className="object-cover"
              sizes="(max-width: 1000px) 50vw, (max-width: 1600px) 33vw, 500px"
            />

            <div className="absolute -top-px left-0 right-0 h-[60%] dark:h-[80%] [backgroundImage:--attraction-gradient]" />
          </div>

          <div className="absolute w-full h-full bg-[rgba(255,255,255,0.1)] dark:bg-[rgba(255,255,255,0.05)] opacity-0 group-hover:opacity-100 transition-opacity group-active:opacity-0" />
        </div>
      ) : null}

      <div className="relative w-full py-4 px-3 @[220px]:p-6 @[300px]:p-8 @[410px]:p-10 rounded-2xl @[220px]:rounded-3xl shadow-[inset_0_0_1px_1px_rgb(0_0_0_/_0.03)] dark:shadow-[inset_0_0_1px_1px_rgb(245_225_255_/_0.1)]">
        <h2 className="[text-wrap:balance] text-current text-lg/tight @[200px]:text-xl/tight @[250px]:text-2xl/tight @[330px]:text-3xl/tight">
          <Link className="expand-link" href={href} prefetch>
            {place.pageTitle || place.name}
          </Link>
        </h2>

        <p
          className={twJoin(
            'mt-2 @[200px]:mt-4 text-xs/snug @[180px]:text-sm/snug @[200px]:text-base/snug @[300px]:text-lg/snug text-current dark:opacity-80 pointer-events-none line-clamp-4 @[200px]:line-clamp-none',
            isDark && 'opacity-80'
          )}
        >
          {place.brief}
        </p>

        <div className="mt-6 hidden @[280px]:flex">
          <BaseButton
            href={href}
            color={'var(--card-text-color)'}
            buttonVariant="translucent"
            className="relative flex [&_.icon-wrapper]:mr-1 @[380px]:px-6 @[380px]:text-xl h-auto py-[10px] @[380px]:h-py-[12] shrink"
          >
            <span>{place.name}</span>
          </BaseButton>
        </div>

        <div className="aspect-[2/1] @[330px]:aspect-[1.7/1]" />

        <BaseButton
          className="flex @[280px]:hidden absolute bottom-3 left-3 w-[42px] items-center justify-center dark:bg-[--card-bg-color]"
          buttonVariant="translucent"
          href={href}
        >
          <RemixIcon icon={arrowRightLine} size={20} color="var(--card-text-color)" />
        </BaseButton>
      </div>
    </article>
  );
};

export default SiteCard;
